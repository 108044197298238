import React from 'react';
import Layout from '../../../components/Layout';
import Section from '../../../components/Section';
import { Container, Row, Column } from '../../../components/Grid';
import Hero from '../../../components/Hero';
import Subfooter from '../../../components/Subfooter';
import '../../../styles/pages/_classroom-instruction.scss';
import '../../../styles/styles.scss';
import '../../../styles/pages/_ccready.scss';
import CareerProfileCard from '../../../components/CareerProfileCard';

const CareerProfiles = () => {
  return (
    <Layout title="Career Profiles">
      <Hero
        mediaSource={'header-Career-Profiles@2x.jpg'}
        className={'hero--large mb-0 pb-0'}
        color={'blue'}
      >
        <h1>CAREER PROFILES</h1>
        <p>
          Discover the diverse pathways available to students in biotech with
          these interactive career explorations.
        </p>
      </Hero>
      <Container>
        <Section>
          <h3 className="text-bold mt-0">
            Meet Real-World Biotech Professionals
          </h3>
          <p>
            Introduce students to a variety of subject matter experts working in
            the field of biotechnology. Get an inside look at the dynamic
            teamwork and creative STEM problem-solving that goes on every day in
            the lab and beyond, as students find out how these unique
            individuals ignited their passion for biotech and created rewarding
            careers.
          </p>
        </Section>
        <Section>
          {/* First row of Career profile cards. Actions property sets the title of the card */}
          <Row>
            <CareerProfileCard
              className={'profile-title-link'}
              to="/college-career-readiness/career-profiles/profile-quality-assurance"
              asCard
              img="img-career-profiles-senior-supervisor-qa@2x.jpg"
              description="Quality Assurance professionals in the field of biotech ensure that the manufacturing of medicine is done safely, effectively, and in compliance with standards. Olajuwon alternates seamlessly between troubleshooting on the manufacturing floor to collaborating in a brainstorming session in the office, thanks to his strong communication and problem-solving skills."
              actions={[
                {
                  label: 'Senior Supervisor of Quality Assurance',
                  type: 'link',
                  path:
                    '/college-career-readiness/career-profiles/profile-quality-assurance',
                },
              ]}
            />
            <CareerProfileCard
              className={'profile-title-link'}
              asCard
              img="img-career-profiles-senior-scientist-development-sciences@2x.jpg"
              description="Senior Scientists in Development Sciences are responsible for getting innovative biotech medications from idea to manufacturing, ensuring that any risks to humans have been identified. Danielle’s perseverance and expert knowledge of Chemistry enable her to overcome challenges in the manufacturing process and turn failures into personal improvements."
              actions={[
                {
                  label: 'Senior Scientist in Development Sciences',
                  type: 'link',
                  path:
                    '/college-career-readiness/career-profiles/profile-developmental-science',
                },
              ]}
            />
            <CareerProfileCard
              className={'profile-title-link'}
              asCard
              img="img-career-profiles-scientist-data-sciences@2x.jpg"
              description="Principal Scientists in Data Sciences are like “disease detectives” who interpret a wide variety of real-world data and search for patterns that can be used to improve healthcare and medication delivery. Preet harnesses the power of data to gain better insight into the way patients experience the medical system, as she studies how medications affect people differently."
              actions={[
                {
                  label: 'Principal Scientist in Development Sciences',
                  type: 'link',
                  path:
                    '/college-career-readiness/career-profiles/profile-data-science',
                },
              ]}
            />
          </Row>
          {/* Second row of career profile cards */}
          <Row>
            <CareerProfileCard
              className={'profile-title-link'}
              asCard
              img="img-career-profiles-senior-project-leader@2x.jpg"
              description="Senior Project Leaders of Companion & Clinical Diagnostics Development work at the forefront of Precision Medicine, using data and patient feedback to improve the effectiveness of a medication by tailoring it to a certain person or group. Doris developed her love of nature as a child into a fascination with DNA, and she brings that same curiosity to her work and collaborations."
              actions={[
                {
                  label:
                    'Senior Project Leader of Companion & Clinical Diagnostics Development ',
                  type: 'link',
                  path:
                    '/college-career-readiness/career-profiles/profile-companion-diagnostic-care',
                },
              ]}
            />
            <CareerProfileCard
              className={'profile-title-link'}
              asCard
              img="img-career-profiles-diversity-inclusion-lead@2x.jpg"
              description="Diversity & Inclusion Leads are responsible for helping to create an inclusive work environment in which all employees feel comfortable being themselves. As a curious and open-minded youth, Doran gravitated toward a science career, but ultimately she discovered her ideal niche working in a role outside of science, supporting the biotech product development lifecycle by ensuring her colleagues can thrive."
              actions={[
                {
                  label: 'Diversity & Inclusion Lead',
                  type: 'link',
                  path:
                    '/college-career-readiness/career-profiles/profile-diversity-and-inclusion',
                },
              ]}
            />
            <CareerProfileCard
              className={'profile-title-link'}
              asCard
              img="img-career-profiles-senior-manager-patient-advocacy@2x.jpg"
              description="Senior Managers of Patient Advocacy Relations work with patient advocacy organizations to understand the patient experience and use data to inform better medical products and services. While not initially interested in STEM as a youth, Katie found a passion for patient advocacy and ended up finding a role where she has the chance to positively impact thousands of lives through her work."
              actions={[
                {
                  label: 'Senior Manager of Patient Advocacy Relations',
                  type: 'link',
                  path:
                    '/college-career-readiness/career-profiles/profile-patient-advocacy',
                },
              ]}
            />
          </Row>
        </Section>
      </Container>
      <Section className="pb-0">
        <Subfooter content=" As the demand for biotech skills in the workforce increases, exciting new careers are emerging and existing careers are being revolutionized." />
      </Section>
    </Layout>
  );
};

export default CareerProfiles;
