import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Container, Row, Column } from '../Grid';
import Action from './Action';
import Image from '../Image';
import { Link, navigate } from 'gatsby';

import './styles.scss';

const CareerProfileCard = ({
  img,
  description,
  actions,
  className,
  asCard,
  to,
}) => {
  const handleClick = (e) => {
    navigate(to);
  };

  const getClassNames = () => classNames('profilecard', className);

  const renderImage = () => {
    let image = (
      <>
        <Image filename={img} className="profilecard__image" />
      </>
    );

    return (
      <>
        <div className="profilecard__image-container">{image}</div>
      </>
    );
  };

  const renderContent = () => (
    <div className="profilecard__content">
      {actions && (
        <div className={classNames('profilecard__actions')}>
          {actions.map((action, i) => (
            <Action key={i} {...action} />
          ))}
        </div>
      )}
      {description && (
        <div
          className={classNames(
            'profilecard__description',
            asCard && 'profilecard__description--as-card',
            'mb-2'
          )}
          dangerouslySetInnerHTML={{ __html: `${description}` }}
        />
      )}
    </div>
  );

  return (
    <Column size={4} onClick={(e) => handleClick(e)}>
      <div className={getClassNames()}>
        {renderImage()}
        {renderContent()}
      </div>
    </Column>
  );
};

CareerProfileCard.defaultProps = {
  description: null,
  actions: null,
  className: null,
};

CareerProfileCard.propTypes = {
  /** The image's file name */
  img: PropTypes.string.isRequired,
  /** The description */
  description: PropTypes.node,
  /** The actions */
  actions: PropTypes.arrayOf(PropTypes.object),
  /** A custom class name */
  className: PropTypes.string,
};

export default CareerProfileCard;
